@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300&display=swap');

.name-container {
    text-align: center;
    margin-top: 50px; /* Adjust spacing as needed */
    font-family: 'Teko', sans-serif; /* Use Teko font */
}

.typewriter-text {
    overflow: hidden; /* Ensures text is hidden initially */
    border-right: .25em solid orange; /* Cursor style */
    white-space: nowrap; /* Keeps text on a single line */
    animation: typing 3.5s steps(30, end), blink-caret .75s step-end infinite; /* Animation settings */
}

@keyframes typing {
    from {
        width: 0; /* Starts with no width */
    }
    to {
        width: 100%; /* Ends with full width */
    }
}

@keyframes blink-caret {
    from, to {
        border-color: transparent; /* Invisible cursor */
    }
    50% {
        border-color: rgb(34, 206, 0); /* Visible cursor */
    }
}
