body {
  margin: 0;
  padding: 0;
  overflow: hidden; /* Prevents scrolling */
}

.lee-img-style {
  margin: 20px;
  border-radius: 50%;
  width: 100px; /* Adjust size as needed */
  height: 100px; /* Adjust size as needed */
  box-shadow: 0 0 10px 5px rgba(0, 255, 255, 0.8); /* Initial glow effect */
  animation: glow 3s infinite alternate; /* Animation for glowing effect */
}

/* @keyframes glow {
  from {
    box-shadow: 0 0 10px 5px rgba(0, 255, 255, 0.8); 
  }
  to {
    box-shadow: 0 0 20px 10px rgba(0, 255, 85, 0.8);
  }
} */

.App {
  text-align: center;
}

.App-header {
  background-color: #000000;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
}

.lee-img-container {
  position: absolute;
  top: 50%;
  left: 2%;
  transform: translateY(-50%);
}

.social-container-main {
  position: absolute;
  top: 2%;
  right: 2%;
  display: flex;
  flex-direction: column; 
  align-items: center; /* Center items horizontally within the container */
}
.toggle-container-main{
  margin-top: 2px; /* Adds space between the icon and the toggle */
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1); 
  /* Adjust the scale to make the toggle smaller */
}
.icon-container {
  display: flex;
  flex-direction: row; 
  /* Any additional styling for icon-container if needed */
}

.copy-text-container {
  margin-top: 10px;  /* 10px gap below the header */
  position: absolute;
  top: calc(100% + 10px); /* Places it below the header */
  right: 2px;             /* 2px from the right side of the window */
}





@media screen and (max-width: 768px) {
  .name-container {
    text-align: right; /* Right-align on smaller screens */
    margin-top: 20px; /* Adjusted spacing */
    position: absolute;
    bottom: 10px; /* Position at the bottom right corner */
    right: 10px;
  }
}


