.social-container {
    display: flex;
    gap: 10px; /* Adjust spacing between icons as needed */
    padding: 10px;
  }
  
  .social-icon {
    text-decoration: none; /* Remove underline */
    color: inherit; /* Ensure the icon inherits the current text color */
    font-size: 24px; /* Adjust icon size as needed */
    transition: color 0.3s ease; /* Smooth transition for color change */
  }
  
  .social-icon:hover {
    color: green; /* Change color to green on hover */
  }
  
  .social-icon i {
    display: inline-block;
  }
  