.copyable-text-container {
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 10px 15px;
    max-width: 400px;
    position: relative;
    /* 3D effect */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2), 
                0px 6px 20px rgba(0, 0, 0, 0.15);
    background-image: linear-gradient(145deg, #ffffff, #e0e0e0);
    transition: transform 0.2s ease;
}

/* Hover effect for added depth */
.copyable-text-container:hover {
    transform: translateY(-3px); /* slightly lifts the component */
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2), 
                0px 10px 24px rgba(0, 0, 0, 0.2);
}

.copyable-text {
    flex-grow: 1;
    margin: 0;
    font-size: 18px; /* Slightly larger font size */
    color: #333; /* Darker text for better readability */
    font-family: 'Arial', sans-serif;
    font-weight: 500; /* Medium weight for a bolder look */
    line-height: 1.5; /* Improved line height for readability */
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1); /* Subtle text shadow for depth */
    transition: color 0.3s ease; /* Transition for color change on hover */
}

/* Change color on hover for interaction */
.copyable-text-container:hover .copyable-text {
    color: #4CAF50; /* Change color on hover */
}

.copy-icon-container {
    cursor: pointer;
    margin-left: 10px;
    padding: 5px;
    border-radius: 50%;
    background-color: #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease; /* Transition for background color */
}

/* Change background color on hover */
.copy-icon-container:hover {
    background-color: #d0d0d0; /* Slightly darker background on hover */
}

.copy-icon {
    color: #555;
    font-size: 18px;
    transition: color 0.3s ease; /* Transition for icon color */
}

/* Change icon color on hover */
.copy-icon-container:hover .copy-icon {
    color: #4CAF50; /* Change icon color on hover */
}

.copied-text {
    position: absolute;
    top: -25px;
    right: 10px;
    background-color: #4CAF50;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    font-family: 'Arial', sans-serif;
    font-weight: 600; /* Bold for emphasis */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* Shadow for depth */
}
