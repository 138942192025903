/* toggle.css */

.checkbox-wrapper-35 .switch {
    display: none;
  }
  
  .checkbox-wrapper-35 .switch + label {
    display: flex;
    align-items: center;
    color: #78768d;
    cursor: pointer;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 15px;
    position: relative;
    user-select: none;
  }
  
  .checkbox-wrapper-35 .switch + label::before,
  .checkbox-wrapper-35 .switch + label::after {
    content: '';
    display: block;
  }
  
  .checkbox-wrapper-35 .switch + label::before {
    background-color: #2f47b5;
    border-radius: 500px;
    height: 15px;
    margin-right: 8px;
    transition: background-color 0.125s ease-out;
    width: 25px;
  }
  
  .checkbox-wrapper-35 .switch + label::after {
    background-color: #fff;
    border-radius: 13px;
    box-shadow: 0 3px 1px 0 rgba(37, 34, 71, 0.05), 0 2px 2px 0 rgba(37, 34, 71, 0.1), 0 3px 3px 0 rgba(37, 34, 71, 0.05);
    height: 13px;
    left: 1px;
    position: absolute;
    top: 1px;
    transition: transform 0.125s ease-out;
    width: 13px;
  }
  
  .checkbox-wrapper-35 .switch + label .switch-x-text {
    display: block;
    margin-right: .3em;
  }
  
  .checkbox-wrapper-35 .switch + label .switch-x-toggletext {
    display: block;
    font-weight: bold;
    height: 15px;
    overflow: hidden;
    position: relative;
    width: 25px;
  }
  
  .checkbox-wrapper-35 .switch + label .switch-x-unchecked,
  .checkbox-wrapper-35 .switch + label .switch-x-checked {
    left: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.125s ease-out, transform 0.125s ease-out;
  }
  
  .checkbox-wrapper-35 .switch + label .switch-x-unchecked {
    opacity: 1;
    transform: none;
  }
  
  .checkbox-wrapper-35 .switch + label .switch-x-checked {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  
  .checkbox-wrapper-35 .switch + label .switch-x-hiddenlabel {
    position: absolute;
    visibility: hidden;
  }
  
  .checkbox-wrapper-35 .switch:checked + label::before {
    background-color: #1bb50d;
  }
  
  .checkbox-wrapper-35 .switch:checked + label::after {
    transform: translate3d(10px, 0, 0);
  }
  
  .checkbox-wrapper-35 .switch:checked + label .switch-x-unchecked {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  
  .checkbox-wrapper-35 .switch:checked + label .switch-x-checked {
    opacity: 1;
    transform: none;
  }
  